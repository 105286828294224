.chat-box-container{
    position: fixed;
    bottom: 65px;
    right: 20px;
    z-index: 19000;
    overflow: hidden;
}

.chat-togglebar{
    cursor: pointer;
    width: 270px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 5px;
    font-weight: bold;
    border-radius: 4px 4px 0px 0px;
    background-color: #526bb1;
    color: white;
    z-index: 35500;
    border-radius: 14px 14px 0px 0px
}
.chat-toggle-icon{
    /* position: absolute; */
    /* right: 1%; */
    /* bottom: 5%; */
    /* z-index: 99; */
    /* width: 100px; */
    /* cursor: pointer; */
}
.message-counter {
    background-color: red; /* You can customize the background color */
    color: white;
    border-radius: 50%;
    padding: 2px 7px;
    position: absolute;
    left: 55px;
    top: 20px;
    z-index: 100;
    pointer-events: none;
    font-size: 10px;
    height: fit-content;

  }
  .chat-toggle-icon-container {
    display: flex;
    position: fixed;
    right: 60px;
    bottom: 50px;
    z-index: 99999;
    /* width: 50px; */
    cursor: pointer;
  }
  @media screen and (max-width:768px)  {
/* @media screen and (max-width: 767px) { */

.message-counter {
    
    position: absolute;
    /* right: 25px; */
    /* bottom: 11.5%; */

}
  }
  