
.navTabContainer {
    width: 40px;
    align-self: flex-end ;
}

.navTabButton {
    display: inline-flex;
    cursor: pointer;
    width: 42px;
    margin: 0px 1px;
    margin-bottom: 3px;
    /* height: 40px; */
    justify-content: center;
    /* padding-right: 16px; */
    /* vertical-align: center; */
    background-color: #ffff;
    border: none;
    /* align-items: center; */
    text-align: center;
    font-size: 14px;
}

.navTabButton:hover{
    background-color: #edeff3d7;
    border-radius: 4px;
}

@media screen and (max-width:767) {
    .navTabContainer {
        width: 40px;
        align-self: flex-end ;
        
    }
    
}