.form-box {
    top: 40%;
    left: 40%;
    position: absolute;
    background-color: white;
    z-index: 9999;
    width: 286px;
    height: 268px;
    border: 2px solid silver;
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* align-items: center; */
    border-radius: 10px;
}
 .ant-modal-wrap {
    pointer-events: none !important;
}
