.mouse {
    position: absolute;
    margin-bottom: 20px;
  }
  
  .user-name {
    font-size: 12px;
    margin-left: 5px;
  }
  .user-img {
    width: 20px;
    height: 20px;
    border-radius: 50%;
  }
  
  