.main-template-container {
    z-index: 999;
    position: absolute;
    left: 0px;
    /* top: 70px; */
    background-color: white;
    border-radius: 2px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    border: 1px solid silver;
    max-height: fit-content;
    /* overflow-y: hidden; */
}

.header-conatiner {
    display: flex;
    gap: 15px;
}

.header-btn-container {
    display: flex;
    margin-top: 6px;
    margin-right: 5px;
    gap: 15px;
    position: absolute;
    right: 5px;
}

.btnn-style {
    background-color: #526bb1;
    color: white;
    border-radius: 5px;
    text-align: center;
    border: 2px solid rgba(192, 192, 192, 0.199);
    height: 30px;
    font-size: 15px;
    font-weight: 400;
    text-align: center;
    padding: 0 8px;
    white-space: nowrap;
}

.preview-btn-style {
    position: relative;
}

.btnn-style:hover {
    background-color: #3c4f7e;
    border: 2px solid #3c4f7e;
    color: #fff;
    cursor: pointer;
}

.header-style {
    display: flex;
    height: 35px;
    width: 100%;
    align-items: center;
    gap: 15px;
    border-radius: 8px;
}

.close-icon-style {
    margin-left: auto;
    font-size: 20px;
    font-weight: 00;
    margin-right: 8px;
    cursor: pointer;
    /* color: white !important; */
}

.editor-container-style {
    width: 100%;
}

.btn-container {
    margin-top: 18px;
    display: flex;
    /* justify-content: center; */
    gap: 25px;
}

.tags-container {
    display: flex;
    flex-wrap: wrap;
    margin-left: 8px;
    margin-top: 5px;
    align-items: center;
    gap: 8px;
}

.all-tag-cotainer {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.header-tag-style {
    background-color: white;
    box-shadow: 1px 1px 4px silver;
}

.header-tag-style:hover {
    font-size: 13px;
    cursor: pointer;
}

.email-tag-style {
    background-color: white;
    box-shadow: 1px 1px 4px silver;
    color: #526bb1 !important;
}

.email-tag-style:hover {
    text-decoration: underline;
    cursor: pointer;
}

.subject-conatiner {
    display: flex;
    align-items: center;
    margin-top: 28px;
    margin-left: 8px;
    gap: 8px;
}
.lable-style{

}
.form-container {
    width: 96.99%;
    margin-top: 28px !important;
    margin-left: 8px !important;
    /* align-items: center; */
    display: flex;
    flex-direction: column;
}

.preview-container-style {
    top: 250px;
    width: 800px;
    height: fit-content;
    z-index: 999999;
    position: absolute;
    border-radius: 10px;
    box-sizing: border-box;
    background-color: rgb(255, 255, 255);
    border: 2px solid rgba(184, 178, 178, 0.199);
    box-shadow: 4px 4px 4px rgba(184, 178, 178, 0.199);
}

.preview-editor-conatiner-style {
    margin-left: 15px;
    margin-top: 8px;
    width: 100%;
    height: 85%;
}

.count-text-style {
    border: 1px solid #d9d9d9;
    height: 25px;
    width: 42px;
    text-align: center;
    font-size: 14px;
    font-family: 'PingFang SC', Helvetica, Arial, sans-serif;
    display: inline-block;
    margin-top: 3px;
}
.form-continer{
    margin-top: -28px;
}
.content-continer{
    width: 100%;
    height: 400px;
}
.bottombar-container{
    display: flex;
    position: absolute;
    bottom: 2px;
    gap: 15px;
    width: 100%;
  }
.counter-container{
    display: flex;
    gap: 15px;
}
.send-container{
    position: absolute;
    display: flex;
    right: 50px;
}