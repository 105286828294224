.btn-style {
  background-color: #526bb1;
  color: white;
  border-radius: 5px;
  margin-left: 8px;
  border: 2px solid rgba(192, 192, 192, 0.199);
  width: 60px;
  height: 30px;
  cursor: pointer;
  font-weight: 400;
  font-size: 15px;
}

.form-style {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  gap: 5px;
}

.sub-form-box {
  width: 360px;
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  margin-top: -10px;

}

.btn-style:hover {
  background-color: #526bb1;
  color: white;
  width: 64px;
  height: 32px;
}