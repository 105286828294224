.title-container{
    margin-bottom: 5px;
}

.title{
    height: 28px;
    width: 120px !important;
    max-width: fit-content !important;
    border: none;
    letter-spacing: 1px;
    font-family: 500 11px Arial, Lato, Source Sans Pro, Roboto, Helvetica, sans-serif;
    font-weight: 500;
    font-size: 20px;
    padding-top: 10px;
    padding-bottom: 15px;
    margin-left: -1px;
}

.title2{
    height: 25px;
    width: fit-content;
    border: none;
    letter-spacing: 1px;
    font-family: 500 11px Arial, Lato, Source Sans Pro, Roboto, Helvetica, sans-serif;
    font-weight: 500;
    font-size: 20px;
    margin-bottom: 22px;
    margin-left: -8px;    
}
.title:focus{
    outline:none;
}

.overflow {
    white-space: nowrap;
    overflow: hidden; 
    text-overflow: ellipsis; 
    width: 120px;
  }


@media screen and (max-width: 480px){
    .title{
        margin: 0;
    }
    .titlewrapper{
            position: relative;
            /* top: 7px; */
            left:-38px;
            font-weight: 600;
            width: 'max-content';
            max-width: '450px';
            overflow: "hidden";
            white-space: 'nowrap';
            text-overflow: 'ellipsis';
            font-size: '16px';

    }
    .privacy{
        position: relative;
        top: -3px;
        left:-35px;

    }
}