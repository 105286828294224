.sharebtn img{
    height:'30px'

}
@media screen and (max-width:768) {
    .sharebtn{
        height:'120px'

    }    
}
