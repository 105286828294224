
.navigationmain {
    display: flex;
    font-size: 25px;
    font-family: Roboto,RobotoDraft,Helvetica,Arial,sans-serif;
    color: #202124;
    align-items: baseline;
    /* margin-left: -8px; */
    margin-left: -11px;
}


@media screen and (max-width: 480px){
    .navigationmain{
        /* display: none; */
        position: relative;
        left: -15px;
    }
}