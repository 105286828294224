.rdw-editor-toolbar
{
    margin-top: 20px;
}
.rdw-editor-main
{
  margin-left: 1rem;
}
.ql-editor{
  overflow-y: none !important;
}