.headerMain {
    display: flex;
    align-items: center;
    flex-wrap:nowrap;
    padding: 0 20px;
}

.headerCol {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    justify-content: flex-end;
    height: 60px;
    /* width: 2px; */
    margin-left: 30px;
}
.titleBarButtons{
    display: flex;
    justify-content: space-around;
    align-items: center;
}
.SavingSaved {
    display: flex;
    width: auto;
    margin-left: 8px;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

@media screen and (max-width: 480px) {
    .headerCol{
        align-items: center;
        justify-content: center;
    }    
    .titleBarButtons{
        width: fit-content;
    }
    .titleBarButtons{
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        gap: 12px;
        top: 5px;
    }
    
}


