/* subForm.css */

.sub-form-container {
    display: flex;
    flex-direction: column;
    background-color: white;
    width: 160px;
    border-radius: 16px;
    box-shadow: 0px 0px 3px 0.2px #00000047;
  }
  
  .sub-form-header {
    display: flex;
    gap: 15px;
  }
  
  .sub-form-title {
    font-size: 16px;
    font-weight: 600;
    margin-top: 5px;
    margin-left: 12px;
  }
  
  .sub-form-close-icon {
    margin-left: auto;
    font-size: 15px;
    margin-top: 8px;
    margin-right: 8px;
    /* color: red !important; */
  }
  .sub-form-select-label
  {
    margin-top: -10px !important;
    margin-left: 12px !important;
  }
  
  .sub-form-select {
    width: 92px !important;
  }
  