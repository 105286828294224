
.logo {
    height: 40px;
    width: 44px;
}
@media screen and (max-width: 767px) {
    .logo {
        position: relative;
        top: 5px;
        height: 30px;
        width: 34px;
    }   
}